import { formatMessage } from "devextreme/localization"
import { getErrorByField } from "../../Headcount/Main/Utils/ErrorHandling"
import { useCompetenceSections } from "../hooks/useCompetenceSections"
import PercentageInput from "./input/PercentageInput"

export default function JobCompetenceRevenueForm({
  job,
  year,
  wbs,
  onValueChanged,
  errors,
  canManage = false,
}) {
  const { competences } = useCompetenceSections()

  return (
    <div className="d-flex flex-column">
      <h6 className="mb-4">{formatMessage("jw-COMPETENCE REVENUES")}</h6>
      <div className="d-flex flex-row flex-wrap mb-4">
        {competences.map((competence) => (
          <PercentageInput
            key={competence}
            className="w-25 pl-3 mb-4"
            label={competence}
            readOnly={!canManage}
            value={
              job?.competenceRevenuesPerc
                ? job?.competenceRevenuesPerc[competence]
                : null
            }
            onChange={(event) => {
              const newCompetenceRevenuesPerc = { ...job.competenceRevenuesPerc }
              const value = event.target.value.trim() === '' ? null : parseFloat(event.target.value)
              newCompetenceRevenuesPerc[competence] = value
              onValueChanged("competenceRevenuesPerc", newCompetenceRevenuesPerc)
            }}
            error={getErrorByField(errors, "competenceRevenuesPerc")}
          />
        ))}
      </div>
    </div>
  )
}
