import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useEffect, useState } from "react"
import Draggable from "react-draggable"
import { createOrganization } from "../../../services/index"
import { Bounce, toast } from "react-toastify"
import { formatMessage } from "devextreme/localization"
import { Checkbox as MaterialUICheckbox } from "@material-ui/core"
import Checkbox from "../JobAndWbs/components/input/Checkbox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons"

const icon = <FontAwesomeIcon icon={faSquare} />
const checkedIcon = <FontAwesomeIcon icon={faCheckSquare} />

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export const AddRow = ({
  togglePopUp,
  setTogglePopUp,
  types,
  teamLeaders,
  areaManagers,
  internalSupportersList,
  externalSupportersList,
  organizationList
}) => {
  const [name, setName] = useState("")
  const [type, setType] = useState(types[0])
  const [owner, setOwner] = useState("")
  const [parent, setParent] = useState("")
  const [deputies, setDeputies] = useState([])
  const [internalSupporters, setInternalSupporters] = useState([])
  const [externalSupporters, setExternalSupporters] = useState([])
  const [possibleSupporters, setPossibleSupporters] = useState([])
  const [possibleInternalSupporters, setPossibleInternalSupporters] = useState([])
  const [possibleExternalSupporters, setPossibleExternalSupporters] = useState([])
  const [possibleOwners, setPossibleOwners] = useState(teamLeaders)
  const [enableSave, setEnableSave] = useState(false)
  const [shortcode, setShortcode] = useState("")
  const [isCompetence, setIsCompetence] = useState(null)

  // load different user according to the UO type
  useEffect(() => {
    switch (type) {
      case "Team":
        setPossibleOwners(teamLeaders)
        setIsCompetence(undefined)
        setDeputies(undefined)
        setShortcode(undefined)
        break
      case "Area":
        setPossibleOwners(areaManagers)
        setIsCompetence(false)
        setPossibleInternalSupporters(internalSupportersList)
        setPossibleExternalSupporters(externalSupportersList)
        break
      default:
      // code block
    }
  }, [type, teamLeaders, areaManagers])

  useEffect(() => {
    setEnableSave(name !== undefined && name !== "")
  }, [name])

  useEffect(() => {
    // do not allow saving if type is area and the shortCode is missing
    if (type === types[1]) setEnableSave(shortcode !== undefined && shortcode !== "")
  }, [type, shortcode])

  const isArea = () => type === types[1]

  const onInsert = async (data) => {
    try {
      data.name = data.name.trim()
      await createOrganization(data)
      setName("")
      setOwner("")
      setType(types[0])
      setParent("")
      setShortcode("")
      setIsCompetence(null)

      setTogglePopUp(false)
      setDeputies([])
      setInternalSupporters([])
      setExternalSupporters([])
    } catch (e) {
      console.log("Error on adding", e.toString())

      let errorResp = e.response
      let errorMessage = ""

      if (errorResp?.status === 409) {
        if (errorResp.data.errors) {
          const paths = []
          e.response.data.errors.forEach((error) => paths.push(error.path))
          errorMessage =
            "Organization already exists: verify " +
            paths.join(", ") +
            " to be unique"
        } else {
          errorMessage = formatMessage(errorResp.data)
        }
      } else if (errorResp?.status === 400) {
        if (e.response.data instanceof Array) {
          errorMessage = formatMessage("invalid-input-data")
        } else {
          errorMessage = errorResp.data
        }
      } else {
        errorMessage = formatMessage("error-creating-organization")
      }

      toast(errorMessage, {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "bottom-center",
        type: "error"
      })
    }
    return
  }

  const render = () => {
    if (setTogglePopUp) {
      return (
        <Dialog
          open={togglePopUp}
          disableBackdropClick
          onClose={() => setTogglePopUp(false)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {formatMessage("add-organization-title")}
          </DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              label={formatMessage("name")}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              variant="outlined"
              style={{ marginTop: 20 }}
              inputProps={{ maxLength: 20 }}
              fullWidth
            />
            <Autocomplete
              id="combo-box-type"
              options={types}
              getOptionLabel={(option) => option}
              defaultValue={type}
              onChange={(item, value) => {
                setType(value)
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} label={type} variant="outlined" />
              )}
              style={{ marginTop: 20 }}
            />
            <Autocomplete
              id="combo-box-owner"
              options={possibleOwners}
              getOptionLabel={(option) => option.name ?? ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              defaultValue={owner}
              key={owner?.id}
              value={owner}
              onChange={(item, value) => {
                setOwner(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={formatMessage("organization-owner")}
                  variant="outlined"
                />
              )}
              fullWidth
              style={{ marginTop: 20 }}
            />
            <Autocomplete
              id="combo-box-org"
              options={organizationList}
              getOptionLabel={(option) => option.name ?? ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              defaultValue={parent}
              key={parent?.id}
              value={parent}
              onChange={(item, value) => {
                setParent(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Parent Organization"}
                  variant="outlined"
                />
              )}
              fullWidth
              style={{ marginTop: 20 }}
            />
            {isArea() && (
              <>
                <TextField
                  id="shortcode"
                  label={"Shortcode"}
                  value={shortcode}
                  onChange={(e) => {
                    setShortcode(e.target.value)
                  }}
                  variant="outlined"
                  style={{ width: "100%", marginTop: 20 }}
                  inputProps={{ maxLength: 20 }}
                />
                <Checkbox
                  disabled={false}
                  readOnly={false}
                  value={isCompetence}
                  onChange={(event) => {
                    setIsCompetence(event.target.checked)
                  }}
                  label={"Competence Area"}
                  style={{ width: "100%", marginTop: 20 }}
                />
              </>
            )}
            {isArea() && (
              <Autocomplete
                id="combo-box-deputies"
                options={possibleOwners}
                multiple={true}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name ?? ""}
                getOptionSelected={(option, value) => option?.id === value?.id}
                defaultValue={deputies}
                key={[deputies]?.id}
                value={deputies}
                onChange={(event, value) => {
                  setDeputies(value)
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Deputies"} variant="outlined" />
                )}
                renderOption={(option, state) => {
                  return (
                    <li style={{ width: "auto" }}>
                      <MaterialUICheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                      />
                      {option.name}
                    </li>
                  )
                }}
                fullWidth
                style={{ marginTop: 20 }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTogglePopUp(false)} color="primary">
              {formatMessage("Cancel")}
            </Button>
            <Button
              disabled={!enableSave}
              onClick={() => {
                let org = {
                  name: name,
                  type: type,
                  owner: owner?.id,
                  parent: parent?.id
                }

                if (isArea()) {
                  org = {
                    ...org,
                    shortcode: shortcode,
                    isCompetence: isCompetence,
                    deputies:
                      deputies != null ? deputies.map((deputy) => deputy.id) : [],
                    internalSupporters:
                      internalSupporters != null
                        ? internalSupporters.map((supporter) => supporter.id)
                        : [],
                    externalSupporters:
                      externalSupporters != null
                        ? externalSupporters.map((supporter) => supporter.id)
                        : []
                  }
                }
                return onInsert(org)
              }}
              color="primary"
            >
              {formatMessage("dxDataGrid-editingSaveRowChanges")}
            </Button>
          </DialogActions>
        </Dialog>
      )
    } else {
      return <div />
    }
  }

  return <div>{render()}</div>
}
